<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col sm:w-full md:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Mot de passe perdu</h4>
                  <vs-divider></vs-divider>
                </div>
                <br/>

                <!-- ETAPE 1 -->
                <div v-if="etape==1">
                  <p>Entrez votre numéro de téléphone</p>
                  <br/>
                    <vue-tel-input v-model="phone"></vue-tel-input>
                    <br/>

                    <div class="vx-col w-full">
                      <span style="color:red">Le login doit contenir plus de 5 caractéres pour pouvoir être changé</span><br/>
                      <vs-input class="w-full" type="Identifiant login" label="Nouveau login ou ancien" v-model="login"/>
                      <br/>
                    </div>
                    <div class="vx-col w-full">
                       <span style="color:red">Le mot de passe doit contenir plus de 8 caractères ainsi que des nombres et un caractère spécial, ex : !, @, : ; $.<br/>
                        Exemple de mot de passe: "nomprenom@1990"</span><br/>
                      <vs-input class="w-full" type="password" label="Nouveau mot de passe" v-model="password"/>
                    </div>
                    <div class="vx-col w-full">
                      <vs-input class="w-full" type="password" label="Ressaisir le nouveau mot de passe" v-model="password2"/>
                    </div>
                    <br/>

                    <vs-button color="primary" class="float-right" v-on:click="FsendCode">Envoyer</vs-button>
                    <br/>
                </div>

                <!-- ETAPE 2 -->
                <div v-if="etape==2">
                  <p>2) Saissisez le code reçu</p>
                  <br/>
                  <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                      <vs-input class="w-full" label="Code reçu par SMS" v-model="code" />
                    </div>
                  </div>
                  <vs-button class="float-right" v-on:click="FupdCompte()">Modifier le mot de passe</vs-button>
                  <br/>
                </div>
                <!-- FIN ETAPE -->

                <br/>
                <vs-divider></vs-divider>
                <router-link to="/">Annuler</router-link>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<style lang="scss">
</style>


<script>
export default {
    data(){
      return {
          phone:"",
          code:"",
          login:"",
          password:"",
          password2:"",
          etape: 1,
      }
    },
    methods:{

      //-----------------------------------------------------
      // Envoi code phase 1
      //-----------------------------------------------------
      FsendCode:function()
      {
        let root = this;
        //get correct phone number
        root.phone = root.phone.split(' ').join('');

        //Verifier les mot de passe
        if( root.password != root.password2 )
          return root.FpasswordDif();

        //Message popup
        root.Fstand();

        //Envoi code SMS
        root.$srvApi.resetUserSendCode(root.phone, root.login, root.password )
        .then(function(){
          return root.etape = 2;
        })
        .catch(function(){
          return root.FnoCompteError();
        });
      },



      //-----------------------------------------------------
      // Envoi code phase 2
      //-----------------------------------------------------
      FupdCompte:function()
      {
        let root = this;

        //Reset password
        root.$srvApi.resetUserUpd(root.code)
        .then(function()
        {
          root.Fsuccess();
          root.$router.push('/');
        })
        .catch(function()
        {
            root.Ferror();
            root.etape = 1;
        });
      },





      //-----------------------------------------------------
      // Popup event
      //-----------------------------------------------------
      Fstand:function()
      {
        this.$vs.notify({
          title:'Envoi du SMS',
          text:'Veuillez patienter ...',
          color:'primary',
          position:'bottom-right'
        });
      },
      FnoCompteError:function()
      {
        this.$vs.notify({
          title:'Envoi SMS impossible',
          text:'Veuillez contacter votre studio',
          color:'warning',
          position:'bottom-right'
        });
      },
      FpasswordDif:function()
      {
        this.$vs.notify({
          title:'Mot de passe différent',
          text:'Les deux mot de passe saisie sont différent',
          color:'warning',
          position:'bottom-right'
        });
      },
      Ferror:function()
      {
        this.$vs.notify({
          title:'Changement impossible',
          text:'Le mot de passe saisie n\'est pas valide',
          color:'warning',
          position:'bottom-right'
        });
      },
      Fsuccess:function()
      {
        this.$vs.notify({
          title:'Modification enregistrée',
          text:'Bienvenue dans votre administration',
          color:'success',
          position:'bottom-right'
        });
      },
    }
};
</script>